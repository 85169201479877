import React from 'react'
import { FaLinkedin } from 'react-icons/fa'
import { GatsbyImage } from 'gatsby-plugin-image'

const BoardMemberCard = ({ profile, image }) => {
  return (
    <div className="board-member-card">
      <div className="board-member-card_header">
        <GatsbyImage className="headshot" image={image} alt={profile.name} />
        <div className="header-text">
          <div className="name">
            <span>{profile.name}</span>
            {profile.linkedin && (
              <a href={profile.linkedin} target="_blank" rel="noreferrer" className="linkedin">
                <FaLinkedin />
              </a>
            )}
          </div>
          <div className="title">{profile.title}</div>
        </div>
      </div>
      <p className="bio">{profile.bio}</p>
    </div>
  )
}
export default BoardMemberCard
