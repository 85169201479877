import React, { useState, useRef, useEffect } from 'react'
import { FaLinkedin } from 'react-icons/fa'
import { GatsbyImage } from 'gatsby-plugin-image'

const AboutUsCard = ({ profile, image }) => {
  const [flipped, setFlipped] = useState(false)
  const bioTextArea = useRef()
  const bioTextFade = useRef()

  const setFadeOpacity = () => {
    const distToBottom = bioTextArea.current.scrollHeight - bioTextArea.current.offsetHeight
    const fadeStartThreshold = 50

    if (bioTextArea.current.scrollTop >= distToBottom - fadeStartThreshold) {
      bioTextFade.current.style.opacity = (distToBottom - bioTextArea.current.scrollTop) / fadeStartThreshold
    } else {
      bioTextFade.current.style.opacity = 1
    }
  }

  useEffect(setFadeOpacity, [])

  return (
    <div className={`about-us-card ${flipped ? 'flipped' : ''}`}>
      <div className="card-front">
        <div className="front-content">
          <GatsbyImage className="headshot" image={image} alt={profile.name} />
          <div className="name">
            <span>{profile.name}</span>
            {profile.linkedin && (
              <a href={profile.linkedin} target="_blank" rel="noreferrer" className="linkedin">
                <FaLinkedin />
              </a>
            )}
          </div>
          <div className="title">{profile.title}</div>
          <p className="career-driver">
            <strong>Overall Career Driver:</strong> {profile.careerDriver}
          </p>
        </div>
        <div className="flip-wrapper">
          <button onClick={() => setFlipped(true)}>Read bio</button>
        </div>
      </div>

      <div className="card-back">
        <div className="back-content">
          <div className="name">
            <span>{profile.name}</span>
            <a href={profile.linkedin} target="_blank" rel="noreferrer" className="linkedin">
              <FaLinkedin />
            </a>
          </div>
          <div className="title">{profile.title}</div>
          <div className="bio-wrapper">
            <div className="bio" ref={bioTextArea} onScroll={setFadeOpacity}>
              <p dangerouslySetInnerHTML={{ __html: profile.bio }}></p>
            </div>
            <div className="bio-fade" ref={bioTextFade}></div>
          </div>
        </div>
        <div className="flip-wrapper">
          <button onClick={() => setFlipped(false)}>Flip back</button>
        </div>
      </div>
    </div>
  )
}

export default AboutUsCard
