export default [
  {
    name: `Neale Trangucci`,
    title: `Chair of the Board`,
    linkedin: `https://www.linkedin.com/in/neale-trangucci-437b44194/`,
    bio: `Neale Trangucci retired in 2014 after 33 years on Wall Street in a variety of roles focused on investing in financially distressed companies. Mr. Trangucci is currently a member of the boards of directors of AdhereTech and Currax Pharmaceuticals. He earned a master’s degree in International Affairs from Columbia University and a bachelor of science degree from Bucknell University.`,
  },
  {
    name: `Dan Raynor`,
    title: `Argentum Private Equity`,
    linkedin: `https://www.linkedin.com/in/daniel-raynor-21224310/`,
    bio: `Dan Raynor is Managing Partner and co-founder of The Argentum Group, a New York-based growth equity firm that helps scale bootstrapped B2B software, technology-enabled, and business services companies. Mr. Raynor received a B.S. in economics from The Wharton School, University of Pennsylvania.`,
  },
  {
    name: `Elliot Zeelander`,
    title: `Mathers Private Equity`,
    linkedin: `https://www.linkedin.com/in/zeelander/`,
    bio: `Elliot Zeelander has more than 30 years of investment management experience. Mr. Zeelander has been the sole General Partner and Managing Director of Mathers Associates since 2014. Today, Mathers manages $100 million, invests in public equity, primary and tertiary private equity, and has over 20 direct co-investments representing 50% of its assets. Mr. Zeelander sits on the boards of AdhereTech, Conner Industries, and EdgeIQ.`,
  },
  {
    name: `Paul Sekhri`,
    title: `Independent Director and CEO of vTv Therapeutics`,
    linkedin: `https://www.linkedin.com/in/paul-sekhri-a1947/`,
    bio: `Mr. Sekhri has over 35 years of experience in the Life Science Industry. His experience encompasses senior management in large corporate pharmaceutical and biotechnology companies, as well as private equity and venture capital. Mr. Sekhri has been a Director on more than 35 private, public company, and non-profit Boards and is currently a member of the Board of Directors of eGenesis, Inc., Spring Discovery, and Veeva Systems Inc.`,
  },
  {
    name: `Josh Stein`,
    title: `AdhereTech Co-Founder`,
    linkedin: `https://www.linkedin.com/in/joshdstein`,
    bio: `Josh Stein cofounded AdhereTech in 2011, providing the vision and business acumen to turn an idea into a thriving healthcare technology business that improves patients’ lives, and spans the complex markets of healthcare, software, enterprise sales, hardware IoT, data analysis and clinical research. Mr. Stein earned his MBA from the Wharton School of Business and a BA from Washington University in St. Louis.`,
  },
  {
    name: `John Langhauser`,
    title: `AdhereTech Co-Founder`,
    linkedin: `https://www.linkedin.com/in/john-langhauser-3542673a`,
    bio: `John Langhauser is the technical co-founder and former Chief Technology Officer at AdhereTech. Prior to cofounding AdhereTech in 2012, he was a software engineer at Bridgewater Associates. Mr. Langhauser holds an MS in Computer Science and a BS in Electrical Engineering and Computer Science from Yale.`,
  },
  {
    name: `Innes Meldrum`,
    title: `AdhereTech CEO`,
    linkedin: `https://www.linkedin.com/in/innesmeldrum/`,
    bio: `With 30 years of leadership experience in both technology and pharmaceuticals, Innes Meldrum brings an authentic, charismatic, cross-cultural leadership approach to successfully deliver business growth through highly engaged organizations. Mr. Meldrum has a BEng in Electrical and Electronic engineering form the University of Strathclyde in Scotland.`,
  },
]
